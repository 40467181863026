<template>
  <div class="synthesize-outer-container">
    <!-- <div
      v-if="
        currentUser.user.synthesizeBanner === -1 &&
        currentUser.user.role.toLowerCase() !== 'trialuser' &&
        isSynthesizeFullAccessBannerCheck() &&
        doUserHavePremiumSubscription
      "
      class="generate-banner"
    >
      {{ $t('synthesize.synthesizeBanner') }}
      <button @click="onDismissBetaBanner" class="dismiss-btn primary--text">
        {{ $t('generate.dismiss') }}
      </button>
    </div> -->
    <div class="synthesize-container">
      <div
        class="main-content"
        :class="{ 'right-panel-open': rightPanelComponent }"
      >
        <div class="page-heading">
          <div class="main">{{ $t('synthesize.heading') }}</div>
          <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
        </div>
        <div class="input-box fl-upldr-bx">
          <div>
            <div class="input-container">
              <div class="input-placeholder" v-if="fileError">
                {{ $t(fileError) }}
              </div>
              <div
                v-else-if="newFilesToUpload.length === 0"
                class="input-placeholder"
                @click="onSynthesisUploadDivClick()"
              >
                Upload your PowerPoint slides (.ppt, .pptx)
              </div>
              <template v-else>
                <v-tooltip bottom fixed>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="input-file-name"
                      :class="{ disabled: step > 0 }"
                      v-bind="attrs"
                      v-on="on"
                      @click="onSynthesisUploadDivClick()"
                    >
                      {{ newFilesToUpload[0].name }}
                    </div>
                  </template>
                  <span>{{ newFilesToUpload[0].name }}</span>
                </v-tooltip>
              </template>
              <template v-if="step === 0">
                <v-tooltip bottom open-delay="500" close-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      small
                      icon
                      color="#21A7E0"
                      v-bind="attrs"
                      v-on="on"
                      @click="onSynthesisUploadButtonClick()"
                      data-pendo-id="synthesis-upload-file"
                    >
                      <v-icon> mdi-tray-arrow-up </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('synthesize.uploadBtn') }}</span>
                </v-tooltip>
              </template>
              <v-tooltip bottom v-if="step === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="display: none"
                  >
                    mdi-home
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
              <input
                type="file"
                ref="hiddenInput"
                :accept="`${allowedUploadTypes.join(', ')}`"
                @change="handleOnDivClickFileSelection"
                style="display: none"
              />
            </div>
            <div class="d-flex justify-end">
              <v-btn
                v-if="step === 0 && !loading && synthesizeLoader.isIdle()"
                elevation="2"
                :disabled="
                  (!newFilesToUpload.length || !!fileError.length) &&
                  !checkingUsage
                "
                :loading="checkingUsage"
                rounded
                color="primary"
                class="synthesize-btn"
                @click="uploadFile"
                data-pendo-id="synthesis-btn"
              >
                {{ $t('slides.synthesize') }}
              </v-btn>
              <div v-else-if="step > 2" class="resynthesize-container">
                <template v-if="!loading && synthesizeLoader.isIdle()">
                  <v-tooltip bottom fixed>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="#41484D"
                        @click="cancelRequest"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <span>{{ $t('synthesize.cancelBtn') }}</span>
                  </v-tooltip>
                </template>
                <v-btn
                  :disabled="!!loading || !synthesizeLoader.isIdle()"
                  elevation="2"
                  rounded
                  class="cancel-btn"
                  @click="resynthesize"
                >
                  Resynthesize
                </v-btn>
              </div>
              <v-btn
                v-else
                elevation="2"
                rounded
                class="cancel-btn"
                @click="cancelRequest"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </div>
          </div>
          <div>
            <div v-if="step < 3 || !!error || !execSummaryLoaded">
              Synthesis works best with decks having up to 20 slides and file
              size under 200MB.
            </div>
            <template v-else>
              <div class="panel-items">
                <template v-for="(action, index) in actions">
                  <template v-if="action.id === 'replaceVisual'">
                    <div
                      v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                      :class="{ current: rightPanelComponent === action.id }"
                      :key="index"
                      :data-pendo-id="action.dataPendoId"
                      @click="openRightPanel(action.id, 'Upload bar')"
                    >
                      <NewFeatureBadge
                        module-name="synthesis"
                        :feature-id="action.featureId"
                        :offsetX="'12.5'"
                        :offsetY="'-5'"
                        :size="'small'"
                      >
                        <v-icon
                          :name="action.id + '-icon'"
                          color="#21A7E0"
                          v-if="action.icon"
                        >
                          {{ action.icon }}
                        </v-icon>
                        <img v-else :src="action.iconSvg[1]" />
                        <span>{{ $t(action.action) }}</span>
                      </NewFeatureBadge>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :class="{ current: rightPanelComponent === action.id }"
                      :key="index"
                      :data-pendo-id="action.dataPendoId"
                      @click="openRightPanel(action.id, 'Upload bar')"
                    >
                      <NewFeatureBadge
                        module-name="synthesis"
                        :feature-id="action.featureId"
                        :offsetX="'12.5'"
                        :offsetY="'-5'"
                        :size="'small'"
                      >
                        <v-icon
                          :name="action.id + '-icon'"
                          color="#21A7E0"
                          v-if="action.icon"
                        >
                          {{ action.icon }}
                        </v-icon>
                        <img v-else :src="action.iconSvg[1]" />
                        <span>{{ $t(action.action) }}</span>
                      </NewFeatureBadge>
                    </div>
                  </template>
                </template>
              </div>
              <div class="d-flex justify-end align-self-end">
                <Ratings
                  v-model="form"
                  @change="handleChangeRating"
                  :disabled="!synthesizeLoader.isIdle()"
                  :origin="'synthesis'"
                  :fileName="newFilesToUpload[0].name"
                  :templateSelected="selectedTemplate.code"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="content-view">
          <template v-if="[1, 2].includes(step) && !error">
            <templates-listing
              :templatesData="templates"
              :loading="loading === 'slides-loading'"
              :primaryBtnText="'Synthesize'"
              :origin="'synthesis'"
              :templateRequestFrom="'Synthesis'"
              @onPrimaryBtnClick="handleTemplateSelection"
              class="slides-selection-container"
              v-if="step === 2"
            ></templates-listing>
            <slides-listing
              :slidesList="slides"
              :loading="loading === 'templates-loading'"
              :origin="'synthesis'"
              @handleOnNext="handleOnSlidesSelectionCta"
              class="slides-selection-container"
              v-else-if="step === 1 && loading !== 'file-upload'"
            ></slides-listing>
            <div class="slides-loader" v-else-if="loading === 'file-upload'">
              <video autoplay loop muted playsinline ref="video">
                <source
                  :src="'/assets/videos/loaders/synthesizeUpload.mp4'"
                  type="video/mp4"
                />
              </video>
            </div>
          </template>
          <SynthesizeDeckViewer
            v-else
            :slides="selectedSlides"
            :error="error"
            :templateName="selectedTemplate"
            :filename="newFilesToUpload"
            :loader="synthesizeLoader"
            :rightPanelOpen="!!rightPanelComponent || leftNavExpanded"
          />
        </div>
        <div class="quick-access-toolbar">
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
              v-if="!removingFromLibrary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    :disabled="step < 3 || !!loading || !!error"
                    :color="hover || isAddedToLibrary ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddToSlideLibrary"
                    data-pendo-id="synthesis-add-to-library-btn"
                  >
                    <v-icon name="add-to-library-icon">
                      {{
                        isAddedToLibrary
                          ? 'mdi-folder-table'
                          : 'mdi-folder-table-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t(addToLibraryTooltip) }}</span>
            </v-tooltip>
            <v-progress-circular
              indeterminate
              :color="'#21A7E0'"
              :size="25"
              v-if="isAddedToLibrary && removingFromLibrary"
            />
          </div>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
              v-if="!removingAsFavorite"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :disabled="step < 3 || !!loading || !!error"
                    :ripple="false"
                    :color="hover || isAddedAsFavorite ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddToFavorites()"
                    data-pendo-id="synthesis-favorite-btn"
                  >
                    <v-icon name="favorite-icon">
                      {{
                        isAddedAsFavorite ? 'mdi-heart' : 'mdi-heart-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t(addToFavoriteTooltip) }}</span>
            </v-tooltip>
            <v-progress-circular
              indeterminate
              :color="'#21A7E0'"
              :size="25"
              v-if="isAddedAsFavorite && removingAsFavorite"
            />
          </div>
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :disabled="step < 3 || !!loading || !!error"
                  :ripple="false"
                  :color="hover ? '#21A7E0' : '#000'"
                  v-bind="attrs"
                  v-on="on"
                  @click="onDownload()"
                  data-pendo-id="synthesis-download-btn"
                >
                  <v-icon name="download-icon"> mdi-download-outline </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
          </v-tooltip>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    :color="showTutorialMenu || hover ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTutorial"
                    data-pendo-id="synthesis-tutorials-btn"
                  >
                    <v-icon name="tutorials-icon"> mdi-television-play </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t('generate.tutorials') }}</span>
            </v-tooltip>
            <v-menu
              v-model="showTutorialMenu"
              :attach="true"
              absolute
              offset-y
              :position-x="-420"
              :position-y="40"
              :nudge-width="460"
              :close-on-click="true"
              :close-on-content-click="false"
              content-class="tutorial-menu"
            >
              <TutorialMenu :category="'Synthesis'"></TutorialMenu>
            </v-menu>
          </div>
          <v-tooltip
            v-if="!rightPanelComponent"
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  :disabled="step < 3 || !!loading || !!error"
                  @click="setSmartTools(true)"
                  :color="hover ? '#21A7E0' : '#000'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon name="right-panel-icon">
                    mdi-square-edit-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.smartTools') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        class="right-panel"
        :class="{
          hide: !rightPanelComponent,
          fade: !synthesizeLoader.isIdle(),
        }"
      >
        <AudienceMenu
          v-if="rightPanelComponent === 'audience'"
          :audience="audience"
          :origin="'synthesis'"
          :fingerprintResponse="true"
          @change="handleConfirmation"
        />
        <SummaryFormats
          v-if="rightPanelComponent === 'format'"
          :selectedSummary="execSummary.format"
          @change="onFormatChange"
        />
        <DataStyle
          v-if="rightPanelComponent === 'dataStyle'"
          :dataStyle="defaultDataStyle"
          @change="onDataStyleChange"
        />
        <Tone
          v-if="rightPanelComponent === 'tone'"
          :tone="execSummary.tone"
          @change="onToneChange"
        />
        <Emotion
          v-if="rightPanelComponent === 'emotion'"
          :emotion="execSummary.emotion"
          @change="onEmotionChange"
        />
        <ReplaceVisual
          v-if="rightPanelComponent === 'replaceVisual'"
          :slide="execSummary"
          :origin="'Synthesis'"
          ref="replaceVisual"
          @changeLayout="openRightPanel('changeLayout')"
          @toggleReplaceImage="onImageReplaced"
          @change="handleConfirmation"
          @replaceStart="startReplaceVisuals"
        />
        <template-selection
          v-if="rightPanelComponent === 'template-menu'"
          :templatesInfo="templates"
          :templateRequestFrom="'Synthesis'"
          :selectedTemplate="selectedTemplate.code"
          @selectedTemplate="changeTemplate"
          class="template-menu"
        ></template-selection>
        <!-- <TemplateMenu
          v-if="rightPanelComponent === 'template-menu'"
          :template="template"
          class="template-selector-active"
          :isCloseBtn="false"
          :origin="'generate'"
          @change="handleConfirmation"
        /> -->
        <ChangeLayout
          v-if="rightPanelComponent === 'changeLayout'"
          :relatedSlides="execSummary.relatedSlides"
          @change="handleConfirmation"
          :origin="'Synthesis'"
        />
        <NodeCount
          v-if="rightPanelComponent === 'nodeCount'"
          :nodeCount="execSummary.nodeCount"
          :siblings="execSummary.siblings"
          :origin="'synthesis'"
          @change="onNodeCountChange"
        />
      </div>
      <div v-if="isSmartToolsOpen" class="smart-tool-switch">
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn
                depressed
                small
                icon
                :ripple="false"
                @click="setSmartTools(false)"
                :color="hover ? '#21A7E0' : '#000'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon name="close-icon"> mdi-close </v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>{{ $t('generate.closeSmartTools') }}</span>
        </v-tooltip>
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
          v-for="(action, index) in actions"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <template>
                <template v-if="action.id === 'replaceVisual'">
                  <v-btn
                    v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                    depressed
                    small
                    icon
                    :ripple="false"
                    @click="openRightPanel(action.id)"
                    :color="
                      rightPanelComponent === action.id || hover
                        ? '#21A7E0'
                        : '#000'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :name="action.id + '-icon'" v-if="action.icon">
                      {{ action.icon }}
                    </v-icon>
                    <img
                      v-else-if="rightPanelComponent === action.id || hover"
                      :src="action.iconSvg[1]"
                    />
                    <img v-else :src="action.iconSvg[0]" />
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    @click="openRightPanel(action.id)"
                    :color="
                      rightPanelComponent === action.id || hover
                        ? '#21A7E0'
                        : '#000'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :name="action.id + '-icon'" v-if="action.icon">
                      {{ action.icon }}
                    </v-icon>
                    <img
                      v-else-if="rightPanelComponent === action.id || hover"
                      :src="action.iconSvg[1]"
                    />
                    <img v-else :src="action.iconSvg[0]" />
                  </v-btn>
                </template>
              </template>
            </v-hover>
          </template>
          <span>{{ $t(action.text) }}</span>
        </v-tooltip>
      </div>
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="replaceVisualCancel"
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
    <!-- <LibraryConfirmationPopup
      v-if="isAddedToLibraryDownload"
      :goToLibrary="goToLibrary"
      :message="$t('premiumFeature.addedToSlideLibrary')"
      :btnText="$t('premiumFeature.openInLibrary')"
      :cancel="closePopup"
    ></LibraryConfirmationPopup> -->
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '../../common/event-bus';
import { removeSpecialCharacters } from '../../utils';
import AudienceMenu from '../GenerateSlides/AudienceMenu.vue';
import NodeCount from '../GenerateSlides/NodeCount.vue';
import ReplaceVisual from '../GenerateSlides/ReplaceVisual/ReplaceVisual.vue';
import ChangeLayout from '../GenerateSlides/ChangeLayout.vue';
import TemplateSelection from '../ComplianceChecker/comply-settings/TemplateSelection.vue';
import SummaryFormats from './SummaryFormats.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import Tone from './Tone.vue';
import Emotion from './Emotion.vue';
import SynthesizeDeckViewer from './SynthesizeDeckViewer/SynthesizeDeckViewer.vue';
import TutorialMenu from '../GenerateSlides/TutorialMenu.vue';
// import LibraryConfirmationPopup from '../../common/LibraryConfimationPopup.vue';
import {
  uploadChunks,
  uploadOPFile,
  checkTaskStatus,
  fetchRecomendedTemplateImages,
  getFeatureUsage,
  postFeatureUsage,
  deleteSlide,
  createSlide,
  deleteFavorite,
  getSlideDetail,
  createFavorite,
} from '../../../utils/api-helper';
import SlidesListing from '../ComplianceChecker/comply-slides-listing-page/SlidesListing.vue';
import TemplatesListing from '../ComplianceChecker/comply-templates-listing-page/TemplatesListing.vue';
import { getFileURL } from '@/utils/calyrex';
// import { getFileURLDownload } from '@/utils/calyrex-test';
// import usersApi from '../../../API/users-api';
import {
  SummaryFormat,
  SynthesizeOperation,
  SlideTone,
  SlideEmotion,
  SummaryFormatMetadata,
  SlideDataStyle,
} from './SynthesizeUtills';
import { SynthesizeService } from './SynthesizeService';
import SynthesizeActionModal from './SynthesizeActionModal';
import Ratings from './Ratings.vue';
// import { downloadFileWithCustomName } from '../../../utils/common';
import { trackSynthesisEvents } from '../../common/Analytics/SynthesisEvents';
import {
  TD_PLACEMENT,
  TD_UPLOAD_CTA,
  TD_UPLOAD_SECTION,
  TD_PREZNAME,
  TD_NUMBER_OF_SLIDES_UPLOADED,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_TEMPLATE,
  TD_RECOMMENDED_TEMPLATE,
  TD_AUD,
  TD_ISDOWNLOAD,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_ISDOWNLOAD_VALUE,
  TD_TEMPLATE_SWITCHED,
  TD_NODE_EDITED_TO,
  TD_EMOTION_SELECTED,
  TD_TONE_SELECTED,
  TD_REPLACE_IMAGE_SOURCE,
  TD_FORMAT_SELECTED,
  TD_NODECOUNT,
  NA,
  TD_SLIDES_LIBRARY,
  TD_SLIDE_NAME,
  TD_LIBRARY,
  TD_FILE_SIZE,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { triggerReplaceImage } from '../../../utils/replace-image-api-helper';
import GenerateActionModal from '../GenerateSlides/GenerateActionModal.vue';
import DataStyle from './DataStyle.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { Loader } from '../../../utils/loader-helper';

export default {
  name: 'SynthesizeContainer',
  components: {
    AudienceMenu,
    NodeCount,
    ReplaceVisual,
    ChangeLayout,
    TemplateSelection,
    SummaryFormats,
    ConfirmationDialog,
    Tone,
    Emotion,
    SynthesizeDeckViewer,
    SlidesListing,
    TemplatesListing,
    Ratings,
    TutorialMenu,
    // LibraryConfirmationPopup,
    DataStyle,
    NewFeatureBadge,
  },
  data() {
    return {
      checkingUsage: false,
      audience: null,
      allowedUploadTypes: ['.ppt', '.pptx'],
      eventStartTime: null,
      loading: null,
      isAddedToLibrary: false,
      isAddedAsFavorite: false,
      removingFromLibrary: false,
      removingAsFavorite: false,
      showConfirmation: false,
      replaceVisualCancel: '',
      dialogPrimary: '',
      dialogTitle: '',
      prezOrSlideId: null,
      // isAddedToLibraryDownload: false,
      dialogText: '',
      form: {
        isLiked: null,
        feedback: '',
      },
      slides: [],
      modifiedFileName: '',
      selectedSlides: [],
      rightPanelComponent: '',
      isSmartToolsOpen: false,
      newFilesToUpload: [],
      fileDetails: null,
      previousTemplate: null,
      pollingInterval: [],
      execSummaryLoaded: false,
      addedToLibraryData: null,
      execSummary: {
        loading: false,
        meta: {},
        landscape: '',
        slide_path: '',
        siblings: [],
        relatedSlides: [],
        slideDetails: {},
        format: null,
        tone: null,
        audience: null,
        placement: null,
        dataStyle: null,
      },
      summaryFormats: {
        [SummaryFormat.ContextCore]: null,
        [SummaryFormat.CurrentStatus]: null,
        [SummaryFormat.SituationComplication]: null,
        [SummaryFormat.What]: null,
        [SummaryFormat.SinceWeSpoke]: null,
        [SummaryFormat.SimplePointers]: null,
      },
      error: null,
      fileError: '',
      templates: null,
      selectedTemplate: null,
      step: 0,
      uploadID: null,
      changeReq: null,
      actions: [
        {
          id: 'audience',
          icon: 'mdi-account-group-outline',
          text: 'synthesize.smartTools.audience',
          action: 'synthesize.actions.audience',
          dataPendoId: 'synthesis-change-audience',
          featureId: '',
        },
        {
          id: 'format',
          iconSvg: [
            '/assets/img/synthesize/format-icon.svg',
            '/assets/img/synthesize/format-icon-filled.svg',
          ],
          text: 'synthesize.smartTools.format',
          action: 'synthesize.actions.format',
          dataPendoId: 'synthesis-change-format',
          featureId: '',
        },
        {
          id: 'dataStyle',
          iconSvg: [
            '/assets/img/synthesize/dataStyle-icon.svg',
            '/assets/img/synthesize/dataStyle-icon-filled.svg',
          ],
          featureId: 'synthesis_modify_data_style_id',
          text: 'synthesize.smartTools.dataStyle',
          action: 'synthesize.actions.dataStyle',
          dataPendoId: 'synthesis-change-datastyle',
        },
        {
          id: 'tone',
          iconSvg: [
            '/assets/img/synthesize/tone-icon.svg',
            '/assets/img/synthesize/tone-icon-filled.svg',
          ],
          text: 'synthesize.smartTools.tone',
          action: 'synthesize.actions.tone',
          dataPendoId: 'synthesis-change-tone',
          featureId: '',
        },
        {
          id: 'emotion',
          iconSvg: [
            '/assets/img/synthesize/emotion-icon.svg',
            '/assets/img/synthesize/emotion-icon-filled.svg',
          ],
          text: 'synthesize.smartTools.emotion',
          action: 'synthesize.actions.emotion',
          dataPendoId: 'synthesis-change-emotion',
          featureId: '',
        },
        {
          id: 'template-menu',
          icon: 'mdi-compare',
          text: 'synthesize.smartTools.template',
          action: 'synthesize.actions.template',
          dataPendoId: 'synthesis-switch-template',
          featureId: '',
        },
        {
          id: 'nodeCount',
          icon: 'mdi-file-tree-outline',
          text: 'synthesize.smartTools.node',
          action: 'synthesize.actions.node',
          dataPendoId: 'synthesis-edit-node-count',
          featureId: '',
        },
        {
          id: 'changeLayout',
          iconSvg: [
            '/assets/img/generate/smartLayout.svg',
            '/assets/img/generate/smartLayout-filled.svg',
          ],
          text: 'synthesize.smartTools.layout',
          action: 'synthesize.actions.layout',
          dataPendoId: 'synthesis-change-layout',
          featureId: '',
        },
        {
          id: 'replaceVisual',
          icon: 'mdi-image-outline',
          text: 'synthesize.smartTools.visual',
          action: 'synthesize.actions.visual',
          dataPendoId: 'synthesis-replace-visuals',
          featureId: '',
        },
      ],
      downloadOperation: {
        loading: false,
        success: false,
      },
      leftNavExpanded: false,
      recordId: null,
      showTutorialMenu: false,
      processInProgress: false,
      startTime: '',
      synthesizeLoader: new Loader('synthesize'),
    };
  },
  provide() {
    return {
      getExecutiveSummary: () => this.execSummary,
      getSummaryFormats: () => this.summaryFormats,
      synthesizeService: this.synthesizeService,
    };
  },
  watch: {},
  computed: {
    ...mapState('users', [
      'currentUser',
      'typesenseSelfAudience',
      'limitsInfoObj',
      'themes',
    ]),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
    ]),
    isSynthesizeFullAccess(state) {
      const synFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'synthesis',
      );
      return (
        (synFeature &&
          synFeature.enabled &&
          synFeature.access_level &&
          synFeature.access_level === 'full_access') ||
        (synFeature &&
          synFeature.access_level &&
          synFeature.access_level === 'restricted_access')
      );
    },
    defaultDataStyle() {
      let dataStyle = '';
      if (this.execSummary?.dataStyle) {
        dataStyle = this.execSummary.dataStyle;
        // } else if (this.audience) {
        //   dataStyle = this.audience?.dataPreference[0]?.toLowerCase();
      } else {
        dataStyle = SlideDataStyle.Objective;
      }
      return dataStyle;
    },
    addToLibraryTooltip() {
      if (this.isAddedToLibrary) {
        return 'generateToolBar.remove_from_slide_library';
      }
      return 'generateToolBar.add_to_slide_library';
    },
    addToFavoriteTooltip() {
      if (this.isAddedAsFavorite) {
        return 'premiumFeature.removeSlideFromFavourite';
      }
      return 'premiumFeature.addSlideToFavourites';
    },
    iconColor() {
      // eslint-disable-next-line no-constant-condition
      return true ? '#757575' : '#000';
    },
    synthesizeService() {
      return new SynthesizeService();
    },
    getDefaultAudience() {
      return {
        id: this.typesenseSelfAudience.id,
        num_id: this.typesenseSelfAudience.num_id,
        type: this.typesenseSelfAudience.audienceType,
        ownerID: this.typesenseSelfAudience.ownerID,
        has_image: 1,
        color_type:
          this.typesenseSelfAudience.question2 === 'leftImage' ? 'high' : 'low',
        has_cartoon:
          this.typesenseSelfAudience.question3 === 'leftImage' ? 0 : 1,
        has_tables:
          this.typesenseSelfAudience.question6 === 'leftImage' ? 1 : 0,
        has_graphs:
          this.typesenseSelfAudience.question6 === 'rightImage' ? 1 : 0,
      };
    },

    mapUserDownloadPreferenceToTooltip() {
      const userDownloadPreference =
        this.currentUser?.user?.userDownloadPreference?.toLowerCase();
      if (userDownloadPreference === 'local_download') {
        return this.$t('common.downloadPPTX');
      }
      if (userDownloadPreference === 'save_to_gdrive') {
        return this.$t('common.googleDownload');
      }
      return this.$t('common.download');
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
      'setAddedToLibOrFavSnackbar',
      'setNavFromAddToLibOrFavSnackbar',
    ]),
    onImageReplaced(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = 'Exit Replace Visuals';
      this.dialogText = `Any changes to the slide will not be saved. Would you like to leave anyway?`;
      this.dialogPrimary = 'Yes';
      this.replaceVisualCancel = 'No';
    },
    goToFavLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlides',
        origin: 'fav_slides',
      });
      this.$modal.hide('SynthesizeActionModal');
      this.$modal.hide('GenerateActionModal');
      this.$router.push('/home/slides').catch(() => {});
    },
    goToLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToLibSlides',
        origin: 'synthesis_lib_slides',
      });
      this.$modal.hide('SynthesizeActionModal');
      this.$modal.hide('GenerateActionModal');
      this.$router.push('/home/slides').catch(() => {});
    },
    // closePopup() {
    //   // this.isAddedToLibraryDownload = false;
    //   this.resetAddedToLibrarySnackbarState();
    // },
    pageChangeAction(navigationNext) {
      this.navigationNext = navigationNext;
      this.$modal.show(
        GenerateActionModal,
        {
          heading: this.$t('premiumFeature.unsavedChanges'),
          primaryBtnText: this.$t('common.save'),
          secondaryBtnText: this.$t('premiumFeature.continueWithoutSaving'),
          modalType: 'unsaved',
          primaryBtnAction: this.saveChanges,
          secondaryBtnAction: this.discardChanges,
          type: 'slide',
          operation: this.downloadOperation,
          filename:
            this.modifiedFileName ||
            `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    async saveChanges(name) {
      await this.updateAddedToSlideLibraryStatus(name);
      this.postCompleteLog('addToPrezentationsLibraryStatus', true);
      this.$modal.hide('GenerateActionModal');
      this.discardChanges();
    },
    discardChanges() {
      this.navigationNext();
    },
    onToneChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.tone.popupTitle');
      this.dialogText = this.$t('synthesize.tone.popupText');
      this.dialogPrimary = this.$t('synthesize.tone.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onEmotionChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.emotion.popupTitle');
      this.dialogText = this.$t('synthesize.emotion.popupText');
      this.dialogPrimary = this.$t('synthesize.emotion.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onNodeCountChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.nodeCount.popupTitle');
      this.dialogText = this.$t('synthesize.nodeCount.popupText');
      this.dialogPrimary = this.$t('synthesize.nodeCount.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onFormatChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.formats.popupTitle');
      this.dialogText = this.$t('synthesize.formats.popupText');
      this.dialogPrimary = this.$t('synthesize.formats.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onDataStyleChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.dataStyle.popupTitle');
      this.dialogText = this.$t('synthesize.dataStyle.popupText');
      this.dialogPrimary = this.$t('synthesize.dataStyle.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onDialogCancel() {
      this.changeReq = null;
      this.showConfirmation = false;
    },
    onDialogConfirm() {
      this.showConfirmation = false;
      this.handleConfirmation(this.changeReq);
    },
    handleChangeRating(change) {
      console.log(change);
      if (change?.feedback.length > 0) {
        this.handleUpdateRecord({
          feedback: change.feedback,
        });
      } else {
        this.handleUpdateRecord({
          reaction: change.isLiked,
        });
      }
    },
    changeTemplate(template) {
      this.previousTemplate = this.selectedTemplate.code;
      this.selectedTemplate = template;
      this.handleConfirmation({
        type: SynthesizeOperation.TemplateChange,
        val: template,
      });
    },
    showTutorial() {
      this.showTutorialMenu = !this.showTutorialMenu;
      trackSynthesisEvents.synthesisTutorialClick(this.currentUser, {
        [TD_TEMPLATE]: NA,
      });
    },
    onDownload() {
      this.$modal.show(
        SynthesizeActionModal,
        {
          heading: `Download slide`,
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          isAddedToLibrary: this.isAddedToLibrary,
          type: 'slide',
          operation: this.downloadOperation,
          filename: `${this.fileDetails.name.replace(/.pptx/g, '')}-synthesis`,
          downloadItem: {
            ...this.execSummary.slideDetails,
            // name: `${this.fileDetails.name.replace(/.pptx/g, '')}-synthesis`,
            prefs: {
              ...this.execSummary.slideDetails.prefs,
              source: 'synthesis',
            },
            filename: this.execSummary.slide_path,
            downloadLoaderId: this.execSummary.slideDetails.unique_id,
          },
          logDownload: () => {
            this.handleUpdateRecord({
              downloadStatus: 'true',
              // finalUserSelectedAudience: this.audience
              //   ? this.audience.id
              //   : this.getDefaultAudience.id,
              // finalUserSelectedTemplate: this.selectedTemplate.code,
              // finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
              // finalFormatSelectedByUser: this.execSummary.format,
              // finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
            });
          },
          closePreviousPopups: this.handleClosePopAndSaveToLib,
        },
        {
          name: 'SynthesizeActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    async handleClosePopAndSaveToLib(name, addToLib) {
      if (addToLib === true && !this.isAddedToLibrary) {
        await this.updateAddedToSlideLibraryStatus(name);
        this.postCompleteLog('addToPrezentationsLibraryStatus', true);
        // this.isAddedToLibraryDownload = true;
        // this.setIsAddedToLibraryDownload(true);
        // this.setPremiumFtLibConfPopupData({
        //   message: this.$t('premiumFeature.addedToSlideLibrary'),
        //   btnText: this.$t('premiumFeature.openInLibrary'),
        //   goToLibrary: this.goToLibrary,
        //   cancel: this.closePopup,
        // });
        // setTimeout(() => {
        //   // this.isAddedToLibraryDownload = false;
        //   this.resetAddedToLibrarySnackbarState();
        // }, 10000);
      }
      this.$modal.hide('SynthesizeActionModal');
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.downloadOperation.loading = true;
      //
      this.setDownloadLoaders({
        loaderId: this.execSummary.slideDetails.unique_id,
        downloading: true,
      });
      this.setShowDownloadSnackbar(true);
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'synthesisDownload',
        logDownloadCallback: async () => {
          this.handleUpdateRecord({
            downloadStatus: 'true',
            // finalUserSelectedAudience: this.audience
            //   ? this.audience.id
            //   : this.getDefaultAudience.id,
            // finalUserSelectedTemplate: this.selectedTemplate.code,
            // finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            // finalFormatSelectedByUser: this.execSummary.format,
            // finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });

          if (addToLibraryClick === true && !this.isAddedToLibrary) {
            await this.updateAddedToSlideLibraryStatus(name);
            // this.isAddedToLibraryDownload = true;
            // this.setIsAddedToLibraryDownload(true);
            // this.setPremiumFtLibConfPopupData({
            //   message: this.$t('premiumFeature.addedToSlideLibrary'),
            //   btnText: this.$t('premiumFeature.openInLibrary'),
            //   goToLibrary: this.goToLibrary,
            //   cancel: this.closePopup,
            // });
            // setTimeout(() => {
            //   // this.isAddedToLibraryDownload = false;
            //   this.resetAddedToLibrarySnackbarState();
            // }, 10000);
          }

          this.postCompleteLog('addToPrezentationsLibraryStatus', true);
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
            [TD_ISDOWNLOAD]: TD_ISDOWNLOAD_VALUE,
            [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
          };
          trackSynthesisEvents.synthesisDownload(this.currentUser, otherData);
        },
        downloadStatusCallback: () => {
          this.downloadOperation.loading = false;
          this.setDownloadLoaders({
            loaderId: this.execSummary.slideDetails.unique_id,
            downloading: false,
          });
          this.$modal.hide('SynthesizeActionModal');
        },
        metaData: {
          ...this.execSummary,
          fileName: name,
          limitsKey: 'slide',
          origin: 'slide',
        },
      });
      //
      // let fileUrl;
      // if (
      //   this.execSummary.slide_path.startsWith(
      //     '/newImageReplaceOutput/output/',
      //   ) ||
      //   this.execSummary.slide_path.startsWith('/output/pptx/')
      // ) {
      //   fileUrl = await getFileURLDownload(
      //     this.currentUser.user.id,
      //     this.execSummary.slide_path,
      //     this.currentUser.userIp,
      //   );
      // } else {
      //   fileUrl = await getFileURL(
      //     this.currentUser.user.id,
      //     this.execSummary.slide_path,
      //     this.currentUser.userIp,
      //     'IOCUpload',
      //   );
      // }
      // this.handleUpdateRecord({
      //   downloadStatus: 'true',
      //   finalUserSelectedAudience: this.audience
      //     ? this.audience.id
      //     : this.getDefaultAudience.id,
      //   finalUserSelectedTemplate: this.selectedTemplate.code,
      //   finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
      //   finalFormatSelectedByUser: this.execSummary.format,
      //   finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
      // });
      // if (addToLibraryClick === true && !this.isAddedToLibrary) {
      //   await this.updateAddedToSlideLibraryStatus(name);
      //   // this.isAddedToLibraryDownload = true;
      //   this.setIsAddedToLibraryDownload(true);
      //   this.setPremiumFtLibConfPopupData({
      //     message: this.$t('premiumFeature.addedToSlideLibrary'),
      //     btnText: this.$t('premiumFeature.openInLibrary'),
      //     goToLibrary: this.goToLibrary,
      //     cancel: this.closePopup,
      //   });
      //   setTimeout(() => {
      //     // this.isAddedToLibraryDownload = false;
      //     this.resetAddedToLibrarySnackbarState();
      //   }, 10000);
      // }
      // downloadFileWithCustomName(fileUrl, name);
      // this.postCompleteLog('addToPrezentationsLibraryStatus', true);
      // this.downloadOperation.loading = false;
      // this.$modal.hide('SynthesizeActionModal');
      // const otherData = {
      //   [TD_PREZNAME]: this.newFilesToUpload[0].name,
      //   [TD_TEMPLATE]: this.selectedTemplate.code,
      //   [TD_AUD]: this.audience
      //     ? this.audience.email
      //     : this.getDefaultAudience.id,
      //   [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      //   [TD_ISDOWNLOAD]: TD_ISDOWNLOAD_VALUE,
      //   [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
      // };
      // trackSynthesisEvents.synthesisDownload(this.currentUser, otherData);
    },
    async startReplaceVisuals(val) {
      console.log('start visuals');
      this.changeReq = {
        type: 'replaceVisuals',
      };
      this.error = null;
      // this.loading = 'synthesizing';
      this.synthesizeLoader = new Loader('synthesizeSmartTools', 1);
      this.execSummary = { ...this.execSummary, loading: true };
      const resp = await triggerReplaceImage(
        val.imageToReplace,
        val.currentSelectedSlideImageData,
        this.execSummary,
        this.currentUser,
      );
      if (!resp.error) {
        this.execSummary = {
          ...this.execSummary,
          slide_path: resp.path,
          thumbnail: resp.thumbnail,
          landscape: resp.landscape,
          loading: false,
        };
        this.$set(
          this.summaryFormats,
          this.execSummary.format,
          this.execSummary,
        );
        this.updateStatsOnResynthesize();
        this.$refs.replaceVisual?.selectImage(
          val.imageToReplace,
          val.currentSelectedSlideImageData,
          this.execSummary.id,
        );
      } else {
        this.error = 'SYNTH_ERR';
      }
    },
    async handleConfirmation(event) {
      let replaceSource = '';
      let matomoReplaceSource = '';
      this.eventStartTime = new Date();
      console.log('event', event);
      this.changeReq = event;
      if (
        this.changeReq.type === SynthesizeOperation.AudienceChange ||
        this.changeReq.type === SynthesizeOperation.TemplateChange
      ) {
        this.summaryFormats = {
          [SummaryFormat.ContextCore]: null,
          [SummaryFormat.CurrentStatus]: null,
          [SummaryFormat.SituationComplication]: null,
          [SummaryFormat.What]: null,
          [SummaryFormat.SinceWeSpoke]: null,
          [SummaryFormat.SimplePointers]: null,
        };
      }
      switch (event.type) {
        case SynthesizeOperation.AudienceChange: {
          this.audience = event.val;
          this.startSynthesize({ audience: event.val });
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience.email,
            [TD_PLACEMENT]: this.placement,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisAudienceChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.ToneChange: {
          this.execSummary.tone = event.data.id;
          this.startSynthesize({ tone: event.data.id });
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_TONE_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: event.data.id,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisToneChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.EmotionChange: {
          this.execSummary.emotion = event.data.id;
          this.startSynthesize({ emotion: event.data.id });
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_EMOTION_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: event.data.id,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisEmotionChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.FormatChange: {
          if (this.summaryFormats[event.value]) {
            this.execSummary = {
              ...this.execSummary,
              ...this.summaryFormats[event.value],
              loading: false,
            };
          } else {
            this.startSynthesize({ summary_format: event.value });
          }
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_FORMAT_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: SummaryFormatMetadata[event.value].label
              .split(',')
              .join('_'),
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisFormatChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.ReplaceVisualChange: {
          this.execSummary = {
            ...this.execSummary,
            slide_path: this.changeReq.path,
            thumbnail: this.changeReq.thumbnail,
            landscape: this.changeReq.landscape,
            loading: false,
          };
          this.updateStatsOnResynthesize();
          this.$set(
            this.summaryFormats,
            this.execSummary.format,
            this.execSummary,
          );
          // setTimeout(() => {
          //   this.loading = null;
          // }, 3500);
          replaceSource = this.$refs.replaceVisual?.imageToReplace?.source;
          if (replaceSource === 'adobe' || replaceSource === 'freepik')
            matomoReplaceSource = 'Library';
          if (replaceSource === 'brand-images')
            matomoReplaceSource = this.currentUser?.company?.displayName;
          if (replaceSource === 'upload') matomoReplaceSource = 'Upload';
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_REPLACE_IMAGE_SOURCE]: matomoReplaceSource,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
          };
          trackSynthesisEvents.synthesisVisualsReplaced(
            this.currentUser,
            otherData,
          );
          this.isAddedToLibrary = false;
          this.isAddedAsFavorite = false;
          break;
        }
        case SynthesizeOperation.TemplateChange: {
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.previousTemplate,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_TEMPLATE_SWITCHED,
            [TD_COMMON_COLUMN_VALUE]: event.val.code,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisTemplateSwitched(
            this.currentUser,
            otherData,
          );
          this.startSynthesize({ template: event.val });
          break;
        }
        case SynthesizeOperation.NodeChange: {
          this.startSynthesize({
            nodeCount: event.slide.prefs.node,
            slideID: event.slide.unique_id,
          });
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_NODECOUNT]: this.execSummary.nodeCount,
            [TD_COMMON_COLUMN_NAME]: TD_NODE_EDITED_TO,
            [TD_COMMON_COLUMN_VALUE]: event.slide.prefs.node,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisNodeEdited(this.currentUser, otherData);
          break;
        }
        case SynthesizeOperation.LayoutChange: {
          this.startSynthesize({
            slideID: event.slide.unique_id,
            category: event.slide.categories[0],
            source: event.slide.filename,
          });
          const otherData = {
            [TD_PREZNAME]: this.newFilesToUpload[0].name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.placement,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          };
          trackSynthesisEvents.synthesisLayoutChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.DataStyleChange:
          this.execSummary.dataStyle = event.data.id;
          await this.startSynthesize({ dataStyle: event.data.id });
          trackSynthesisEvents.synthesisModifyDatastyleSelected(
            this.currentUser,
            {
              [TD_TIME_SPENT_IN_SECS]:
                (new Date() - this.eventStartTime) / 1000,
              [TD_TEMPLATE]: this.selectedTemplate?.code,
              [TD_PLACEMENT]: this.placement,
              [TD_COMMON_COLUMN_NAME]: 'Value selected',
              [TD_COMMON_COLUMN_VALUE]: this.$t(event.data.label),
              [TD_SLIDE_NAME]: this.newFilesToUpload[0].name,
            },
          );
          break;
        default:
      }
    },
    handleOnDivClickFileSelection(event) {
      this.previewFiles(event.target.files);
    },
    onUploadDivClick() {
      if (this.step === 0 && !this.loading) {
        this.$refs.hiddenInput.click();
      }
    },
    onSynthesisUploadButtonClick() {
      this.onUploadDivClick();
      const otherData = {
        [TD_PLACEMENT]: TD_UPLOAD_CTA,
      };
      trackSynthesisEvents.synthesisUpload(this.currentUser, otherData);
    },
    onSynthesisUploadDivClick() {
      this.onUploadDivClick();
      const otherData = {
        [TD_PLACEMENT]: TD_UPLOAD_SECTION,
      };
      trackSynthesisEvents.synthesisUpload(this.currentUser, otherData);
    },
    // onDismissBetaBanner() {
    //   const userData = {
    //     performIncrements: {
    //       synthesizeBanner: 1,
    //     },
    //   };
    //   usersApi.methods
    //     .updateUserProfile(userData)
    //     .then((updatedUser) => {
    //       const userInfo = this.currentUser;
    //       userInfo.user = updatedUser;
    //       this.setCurrentUser(userInfo);
    //     })
    //     .catch((err) => console.log(err));
    // },
    // isSynthesizeFullAccessBannerCheck() {
    //   const synFeature = this.currentUser?.features?.find(
    //     (ft) => ft.feature_name === 'synthesis',
    //   );
    //   return (
    //     synFeature &&
    //     synFeature.enabled &&
    //     synFeature.access_level &&
    //     synFeature.access_level === 'full_access'
    //   );
    // },
    async uploadFile() {
      const otherData = {
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
      };
      trackSynthesisEvents.synthesisUploadSynthesize(
        this.currentUser,
        otherData,
      );
      this.checkingUsage = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
          this.checkingUsage = false;
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.synthesis?.isLimitCrossed ||
        this.limitsInfoObj?.synthesis?.isFullAccess
      ) {
        this.error = null;
        EventBus.$emit('MINIMIZE_LEFTNAV');
        this.step = 1;
        this.loading = 'file-upload';
        if (this.newFilesToUpload.length > 0) {
          try {
            if (this.newFilesToUpload[0].file.size / 1024 / 1024 > 200) {
              if (this.step === 1) {
                const otherDatas = {
                  [TD_NUMBEROFSLIDES]: this.slides.length,
                  [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                  [TD_FILE_SIZE]: `${
                    this.newFilesToUpload[0]?.file?.size
                      ? this.newFilesToUpload[0].file.size / 1024 / 1024
                      : 0
                  } MB`,
                };
                trackSynthesisEvents.synthesisUploadSynthesizeFailed(
                  this.currentUser,
                  otherDatas,
                );
                this.cancelRequest();
                this.error = 'SIZE_ERR';
              }
            } else {
              const preProcessResp = await uploadChunks(
                this.newFilesToUpload[0],
                'synthesize',
              );
              if (preProcessResp && this.step === 1) {
                const postProcessUpload = await uploadOPFile(
                  this.newFilesToUpload[0].requestIdentifier,
                  this.newFilesToUpload[0].name,
                  'synthesize',
                );
                console.log(postProcessUpload);
                if (postProcessUpload && this.step === 1) {
                  this.newFilesToUpload[0] = {
                    ...this.newFilesToUpload[0],
                    ...postProcessUpload.data,
                    processingCompleted: true,
                  };
                  const context = {
                    s3_path: this.newFilesToUpload[0].s3Prefix,
                    s3_bucket: this.newFilesToUpload[0].s3Bucket,
                  };

                  await this.handleCreateRecord();

                  if (this.step === 1) {
                    const { jobId, output } =
                      await this.synthesizeService.startUpload(
                        context,
                        'U-Thumbnails',
                      );
                    this.newFilesToUpload[0] = {
                      ...this.newFilesToUpload[0],
                      uploadId: jobId,
                    };
                    this.uploadID = jobId;
                    // eslint-disable-next-line prefer-destructuring
                    this.fileDetails = this.newFilesToUpload[0];
                    this.slides = await this.getImages(output);
                    this.loading = null;

                    await this.handleUpdateRecord({
                      uploadedPrezentationThumbnail: JSON.stringify(
                        this.slides.map((slide) => slide.thumbnail_jpg.s3_path),
                      ),
                      prezentationName: this.fileDetails.name,
                      totalSlideCount: this.selectedSlides.length || undefined,
                      totalSlideInDeck: this.slides.length || undefined,
                      fileSizeKb: this.fileDetails.sizeKb,
                    });
                  }
                } else if (this.step === 1) {
                  const otherDatas = {
                    [TD_NUMBEROFSLIDES]: this.slides.length,
                    [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                    [TD_FILE_SIZE]: `${
                      this.newFilesToUpload[0]?.file?.size
                        ? this.newFilesToUpload[0].file.size / 1024 / 1024
                        : 0
                    } MB`,
                  };
                  trackSynthesisEvents.synthesisUploadSynthesizeFailed(
                    this.currentUser,
                    otherDatas,
                  );
                  this.cancelRequest();
                  this.error = 'SYNTH_ERR';
                }
              }
            }
          } catch (err) {
            let numOfPages = 0;
            if (err?.response?.data?.data) {
              numOfPages = err.response.data.data.numOfPages;
            }
            if (this.step === 1) {
              const otherDatas = {
                [TD_NUMBEROFSLIDES]: numOfPages,
                [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                [TD_FILE_SIZE]: `${
                  this.newFilesToUpload[0]?.file?.size
                    ? this.newFilesToUpload[0].file.size / 1024 / 1024
                    : 0
                } MB`,
              };
              trackSynthesisEvents.synthesisUploadSynthesizeFailed(
                this.currentUser,
                otherDatas,
              );
              this.cancelRequest();
              this.error = numOfPages > 30 ? 'UPL_ERR' : 'SYNTH_ERR';
              this.loading = null;
            }
          }
        }
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'synthesis' });
      }
    },
    getImages(slides) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const slideList = [];
          for (const slide of slides) {
            const slideObj = {
              ...slide,
              key: slide.index,
            };
            slideObj.thumbnail_jpg.thumbnail = '';
            slideObj.thumbnail_jpg.thumbnail = await getFileURL(
              this.currentUser.user.id,
              slide.thumbnail_jpg.s3_path,
              this.currentUser.userIp,
              'IOCUpload',
            );
            slideList.push(slideObj);
          }
          resolve(slideList);
        } catch (err) {
          reject(err);
        }
      });
    },
    async handleOnSlidesSelectionCta(slides) {
      console.log(slides);
      try {
        this.selectedSlides = [...slides];
        this.loading = 'templates-loading';
        const resp = await checkTaskStatus(
          this.uploadID,
          (interval) => {
            console.log(interval);
            this.synthesizeService.intervals.push(interval);
            // this.pollingInterval.push(interval);
          },
          'Recommended-Templates',
        );
        console.log(resp);
        this.templates = await fetchRecomendedTemplateImages(
          resp[0],
          [...this.themes],
          'synthesis',
        );
        console.log(this.templates);
        this.step = 2;
        this.loading = null;
      } catch (err) {
        console.log(err);
        this.cancelRequest();
        this.error = 'SYNTHSIZE_ERR';
        this.loading = null;
        await this.handleUpdateRecord({
          synthesisStatus: 'Failed',
        });
      }
      const otherData = {
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_COMMON_COLUMN_NAME]: TD_NUMBER_OF_SLIDES_UPLOADED,
        [TD_COMMON_COLUMN_VALUE]: this.slides.length,
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
      };
      trackSynthesisEvents.synthesisUploadSynthesizeNext(
        this.currentUser,
        otherData,
      );
    },
    handleTemplateSelection(template) {
      this.selectedTemplate = template;
      this.execSummaryLoaded = false;
      postFeatureUsage({ feature: 'synthesis' })
        .then((response) => {
          console.log(response);
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      this.summaryFormats = {
        [SummaryFormat.ContextCore]: null,
        [SummaryFormat.CurrentStatus]: null,
        [SummaryFormat.SituationComplication]: null,
        [SummaryFormat.What]: null,
        [SummaryFormat.SinceWeSpoke]: null,
        [SummaryFormat.SimplePointers]: null,
      };
      this.startSynthesize();
      // console.log(
      //   this.audience ? this.audience.id : this.getDefaultAudience.id,
      // );
      const otherData = {
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_COMMON_COLUMN_NAME]: TD_RECOMMENDED_TEMPLATE,
        [TD_COMMON_COLUMN_VALUE]: this.templates.recommended_templates
          ?.map((item) => item?.code)
          .join('/ '),
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
        [TD_TEMPLATE]: this.selectedTemplate.code,
        [TD_AUD]: this.getDefaultAudience.id,
      };
      trackSynthesisEvents.synthesisUploadSynthesizeNextSynthesize(
        this.currentUser,
        otherData,
      );
    },
    async resynthesize() {
      this.checkingUsage = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
          this.checkingUsage = false;
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.synthesis?.isLimitCrossed ||
        this.limitsInfoObj?.synthesis?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'synthesis' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
        this.synthesizeService.cancel();
        this.execSummaryLoaded = false;
        this.recordId = null;
        this.changeReq = null;
        this.error = null;
        this.execSummary = {
          loading: true,
          meta: {},
          landscape: '',
          slide_path: '',
          siblings: [],
          relatedSlides: [],
          slideDetails: {},
          format: null,
          tone: null,
          audience: null,
          dataStyle: null,
        };
        this.setSmartTools(false);
        this.startSynthesize();
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'synthesis' });
      }
      this.summaryFormats = {
        [SummaryFormat.ContextCore]: null,
        [SummaryFormat.CurrentStatus]: null,
        [SummaryFormat.SituationComplication]: null,
        [SummaryFormat.What]: null,
        [SummaryFormat.SinceWeSpoke]: null,
        [SummaryFormat.SimplePointers]: null,
      };
      const otherData = {
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
        [TD_TEMPLATE]: this.selectedTemplate.code,
      };
      trackSynthesisEvents.synthesisResynthesise(this.currentUser, otherData);
    },
    getFormat(format, node) {
      if (node && format === SummaryFormat.SimplePointers) {
        return this.summaryFormats[format]
          ? `${node} ${format}`
          : `5 ${format}`;
      }
      if (!node && format.includes(SummaryFormat.SimplePointers)) {
        return SummaryFormat.SimplePointers;
      }
      return format;
    },
    async startSynthesize(contextOptions = {}) {
      if (this.step === 2 && JSON.stringify(contextOptions) === '{}') {
        this.processInProgress = true;
        this.startTime = performance.now();
      } else {
        this.processInProgress = false;
      }
      this.error = null;
      // this.loading = 'synthesizing';
      if (Object.keys(contextOptions).length === 0) {
        this.synthesizeLoader = new Loader('synthesize', 1);
      } else {
        this.synthesizeLoader = new Loader('synthesizeSmartTools', 1);
      }
      this.step = 3;
      this.execSummary = { ...this.execSummary, loading: true };
      if (
        this.changeReq &&
        this.changeReq.type !== SynthesizeOperation.LayoutChange &&
        this.changeReq.type !== SynthesizeOperation.NodeChange
      ) {
        await this.synthesizeService.cancel();
      }
      const context = {
        s3_path: this.newFilesToUpload[0].s3Prefix,
        s3_bucket: this.newFilesToUpload[0].s3Bucket,
        slide_indexes: this.selectedSlides.map((i) => i.index),
        company: this.currentUser.company.name,
        template: this.selectedTemplate.code,
        audience: this.audience || this.getDefaultAudience,
        dataStyle: SlideDataStyle.Objective,
        ...(this.changeReq && {
          summary_format: this.getFormat(
            contextOptions.summary_format || this.execSummary.format,
            contextOptions.nodeCount || this.execSummary.nodeCount,
          ),
          tone: contextOptions.tone || this.execSummary.tone,
          emotion: contextOptions.emotion || this.execSummary.emotion,
          dataStyle:
            contextOptions.dataStyle ||
            this.execSummary?.dataStyle ||
            SlideDataStyle.Objective,
          operation: this.changeReq ? this.changeReq.type : null,
          override: { workflow: this.execSummary.id },
          ...(this.changeReq.type === SynthesizeOperation.NodeChange && {
            node: contextOptions.nodeCount,
            slide_id: contextOptions.slideID,
          }),
          ...(this.changeReq.type === SynthesizeOperation.LayoutChange && {
            slide_id: contextOptions.slideID,
            exec_metadata: {
              ...this.execSummary.meta,
              category: contextOptions.category,
              source_path: contextOptions.source,
            },
          }),
        }),
      };

      try {
        const { jobId, slideOutput, time } = await this.synthesizeService.start(
          context,
        );

        const execSummary = await this.synthesizeService.fetchSlideMetadata(
          {
            ...slideOutput,
            format:
              context.summary_format ||
              slideOutput.suggested_summary_format ||
              this.execSummary.format,
          },
          this.currentUser,
          this.changeReq,
        );
        execSummary.format = this.getFormat(
          context.summary_format ||
            slideOutput.suggested_summary_format ||
            this.execSummary.format,
        );
        execSummary.tone =
          contextOptions.tone || this.execSummary.tone || SlideTone.Neutral;
        execSummary.emotion =
          contextOptions.emotion ||
          this.execSummary.emotion ||
          SlideEmotion.Optimistic;
        execSummary.dataStyle =
          contextOptions.dataStyle || this.defaultDataStyle;
        // execSummary.audience = contextOptions.audience;
        execSummary.id = jobId;
        if (this.changeReq?.type === SynthesizeOperation.LayoutChange) {
          execSummary.relatedSlides = this.execSummary.relatedSlides;
        }

        this.execSummary = { ...execSummary, loading: false };
        this.$set(this.summaryFormats, execSummary.format, execSummary);
        this.execSummaryLoaded = true;
        if (!this.changeReq) {
          this.openRightPanel('changeLayout');
        }
        const payload = {
          identifiedTemplate: this.execSummary.slideDetails?.prefs?.theme,
          initialUserSelectedTemplate: this.selectedTemplate.code,
          initialAudience: this.audience
            ? this.audience.id
            : this.getDefaultAudience.id,
          initialSummaryFormat: this.execSummary.format,
          initialSlideTone: this.execSummary.tone,
          initialContentEmotion: this.execSummary.emotion,
          initialNodeCount: Number(this.execSummary.nodeCount),
          timeTakenSeconds: time,
          initialExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
          initialExecutiveSummarySlidePptx: this.execSummary.slide_path,
          intialDataStyle:
            this.execSummary.dataStyle || SlideDataStyle.Objective,
          synthesisStatus: 'Success',
          totalSlideCount: this.selectedSlides.length || undefined,
          totalSlideInDeck: this.slides.length || undefined,
        };

        if (!this.recordId) {
          await this.handleCreateRecord(payload);
        } else if (!this.changeReq?.type) {
          await this.handleUpdateRecord(payload);
        } else if (
          [
            SynthesizeOperation.NodeChange,
            SynthesizeOperation.ToneChange,
            SynthesizeOperation.EmotionChange,
          ].includes(this.changeReq?.type)
        ) {
          const payloads = {
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
            synthesisStatus: 'Success',
          };
          switch (this.changeReq?.type) {
            case SynthesizeOperation.ToneChange: {
              payloads.changeToneStatus = true;
              break;
            }
            case SynthesizeOperation.EmotionChange: {
              payloads.changeEmotionStatus = true;
              break;
            }
            case SynthesizeOperation.NodeChange: {
              payloads.editNodeCountStatus = true;
              break;
            }
            default:
          }
          await this.handleUpdateRecord(payloads);
        } else {
          this.updateStatsOnResynthesize();
        }
        this.isAddedToLibrary = false;
        this.isAddedAsFavorite = false;
        this.processInProgress = false;
      } catch (err) {
        console.log(err);
        this.error = 'SYNTH_ERR';
        this.loading = null;
        this.processInProgress = false;
        await this.handleUpdateRecord({
          synthesisStatus: 'Failed',
        });
      }
    },
    async updateStatsOnResynthesize() {
      switch (this.changeReq.type) {
        case SynthesizeOperation.AudienceChange: {
          await this.handleUpdateRecord({
            changeAudienceStatus: true,
            finalUserSelectedAudience: this.audience
              ? this.audience.id
              : this.getDefaultAudience.id,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.ToneChange: {
          await this.handleUpdateRecord({
            changeToneStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.EmotionChange: {
          await this.handleUpdateRecord({
            changeEmotionStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.FormatChange: {
          await this.handleUpdateRecord({
            changeFormatStatus: true,
            finalFormatSelectedByUser: this.execSummary.format,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.ReplaceVisualChange: {
          await this.handleUpdateRecord({
            replaceVisualStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.TemplateChange: {
          await this.handleUpdateRecord({
            switchTemplateStatus: true,
            finalUserSelectedTemplate: this.selectedTemplate.code,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.NodeChange: {
          await this.handleUpdateRecord({
            editNodeCountStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.LayoutChange: {
          await this.handleUpdateRecord({
            changeLayoutStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.DataStyleChange:
          await this.handleUpdateRecord({
            changeDataStyleStatus: true,
            finalDataStyle: this.execSummary.dataStyle,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        default:
      }
    },
    async cancelRequest() {
      if (this.step === 1) {
        const otherData = {
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
        };
        trackSynthesisEvents.synthesisUploadSynthesizeCancel(
          this.currentUser,
          otherData,
        );
      }
      if (this.step === 2) {
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
        };
        trackSynthesisEvents.synthesisUploadSynthesizeNextCancel(
          this.currentUser,
          otherData,
        );
      }
      this.synthesizeLoader.nextState(0);
      this.processInProgress = false;
      this.setSmartTools(false);
      this.step = 0;
      this.selectedSlides = [];
      this.slides = [];
      this.newFilesToUpload = [];
      this.audience = null;
      this.selectedTemplate = null;
      this.loading = null;
      this.fileDetails = null;
      this.$refs.hiddenInput.value = null;
      this.changeReq = null;
      this.error = null;
      this.execSummaryLoaded = false;
      this.execSummary = {
        loading: true,
        meta: {},
        landscape: '',
        slide_path: '',
        siblings: [],
        relatedSlides: [],
        slideDetails: {},
        format: null,
        tone: null,
        audience: null,
        dataStyle: null,
      };
      this.resetSlideLibraryFlags();
      await this.synthesizeService.cancel();
    },
    async handleCreateRecord(data = {}) {
      this.form = {
        isLiked: null,
        feedback: '',
      };
      const payload = {
        uploadedPrezentation: this.newFilesToUpload[0].s3Prefix,
        uploadedPrezentationThumbnail: this.selectedSlides?.length
          ? JSON.stringify(
              this.slides.map((slide) => slide.thumbnail_jpg.s3_path),
            )
          : undefined,
        prezentationName: this.fileDetails?.name,
        totalSlideCount: this.selectedSlides.length || undefined,
        totalSlideInDeck: this.slides.length || undefined,
        fileSizeKb: this.fileDetails?.sizeKb,
        ...data,
      };
      const id = await this.synthesizeService.createStats(payload);
      this.recordId = id;
    },
    async handleUpdateRecord(updates) {
      await this.synthesizeService.updateStats(this.recordId, updates);
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/synthesize/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    async previewFiles(event) {
      // Check file type
      const file = event[0];
      if (!['pptx', 'ppt'].includes(file.name.split('.').pop())) {
        this.fileError = 'userUploadPrezentation.invalidFileFormat';
        return;
      }
      this.fileError = '';
      this.newFilesToUpload = [...event];
      this.newFilesToUpload = this.newFilesToUpload.map((i) => ({
        file: i,
        name: i.name,
        status: 'Processing',
        progress: 0,
        path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
        processingCompleted: false,
        requestIdentifier: uuidv4(),
      }));
    },
    resetSlideLibraryFlags() {
      this.isAddedToLibrary = false;
      this.isAddedAsFavorite = false;
      this.addedToLibraryData = null;
      this.modifiedFileName = '';
    },
    postCompleteLog(type, val) {
      const payload = {
        [type]: val,
      };
      this.handleUpdateRecord(payload).then((resp) => {
        console.log(resp);
      });
    },
    handleAddToSlideLibrary() {
      if (this.isAddedToLibrary) {
        console.log('remove from library');
        // this.isAddedToLibraryDownload = false;
        // this.resetAddedToLibrarySnackbarState();
        this.removingFromLibrary = true;
        deleteSlide(this.addedToLibraryData.info.unique_id)
          .then(() => {
            this.postCompleteLog('addToPrezentationsLibraryStatus', false);
            this.resetSlideLibraryFlags();
            this.removingFromLibrary = false;
            this.$modal.hide('SynthesizeActionModal');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$modal.show(
          SynthesizeActionModal,
          {
            heading: this.$t('generateToolBar.add_to_slide_library'),
            primaryBtnText: this.$t('premiumFeature.addToLibrary'),
            modalType: 'slidesLibrary',
            type: 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            operation: this.downloadOperation,
            filename:
              this.modifiedFileName ||
              `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          },
          {
            name: 'SynthesizeActionModal',
            width: '620px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },
    async handleAddToFavorites() {
      if (this.isAddedAsFavorite) {
        this.removingAsFavorite = true;
        const params = {
          assetIds: [this.addedToLibraryData.info.unique_id],
          getFavourite: true,
        };
        const resp = await getSlideDetail(params);
        const favSlideId =
          resp.data[this.addedToLibraryData.info.unique_id].favouriteId;
        await deleteFavorite(favSlideId)
          .then(() => {
            this.postCompleteLog('addToPrezentationToFavoritesStatus', false);
            this.isAddedAsFavorite = false;
            this.removingAsFavorite = false;
            this.$modal.hide('SynthesizeActionModal');
          })
          .catch((err) => console.log(err));
        console.log('removing favorites');
      } else {
        this.$modal.show(
          SynthesizeActionModal,
          {
            heading: this.$t('premiumFeature.addSlideToFavourites'),
            primaryBtnText: this.$t('slideDetails.addFavorite'),
            modalType: 'favorites',
            type: 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            isAddedToLibrary: this.isAddedToLibrary,
            operation: this.downloadOperation,
            filename:
              this.modifiedFileName ||
              `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          },
          {
            name: 'SynthesizeActionModal',
            width: '620px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },
    async addToFavorite() {
      try {
        const favoriteData = {
          id: uuidv4(),
          assetID: this.addedToLibraryData.info.unique_id,
          source: this.addedToLibraryData.info.tags.source,
          themeID: this.selectedTemplate?.code,
          tailorID: this.audience?.id,
          audienceType: this.audience?.type,
        };
        await createFavorite(favoriteData).then(() => {
          this.postCompleteLog('addToPrezentationToFavoritesStatus', true);
          this.isAddedAsFavorite = true;
          this.downloadOperation.success = true;
          this.downloadOperation.loading = false;
        });
      } catch (error) {
        this.downloadOperation.success = false;
        this.downloadOperation.loading = false;
      }
    },
    async updateAddedToSlideLibraryStatus(name, addFavorite = false) {
      if (addFavorite)
        trackSynthesisEvents.synthesisAddToFavourite(this.currentUser, {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_SLIDE_NAME]:
            name || `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]: TD_SLIDES_LIBRARY,
        });
      else
        trackSynthesisEvents.synthesisAddToSlideLibrary(this.currentUser, {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_SLIDE_NAME]:
            name || `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]: TD_SLIDES_LIBRARY,
        });
      if (this.isAddedToLibrary && addFavorite) {
        await this.addToFavorite();
        this.setAddedToLibOrFavSnackbar({
          show: true,
          ctaText: 'snackBarItems.goToFavorites',
          snackbarText: 'snackBarItems.slideAddedToFav',
          navFn: this.goToFavLibrary,
          snackbarType: 'goToFav',
        });
        return;
      }
      this.modifiedFileName = name;
      this.downloadOperation.loading = true;
      const payload = {
        source: 'synthesis',
        isShare: false,
        sharedEntities: [],
        slidesData: [
          {
            tags: {
              uniqueID: this.execSummary.slideDetails?.unique_id,
              node: this.execSummary.nodeCount,
              construct:
                this.execSummary.slideDetails?.constructs.join(', ') || '',
              ...this.execSummary.slideDetails?.prefs,
              source: 'synthesis',
            },
            business_ideas: ['Executive summary'],
            categories: this.execSummary.slideDetails?.categories || [],
            constructs:
              this.execSummary.slideDetails?.constructs.join(', ') || '',
            title: name,
            summary: '',
            filename: this.execSummary.slide_path,
            landscape: this.formatProperty(this.execSummary.thumbnail),
            thumbnail: this.formatProperty(this.execSummary.thumbnail),
            type: 'synthesis',
            templateCode: this.selectedTemplate?.code || '',
            audience: this.audience
              ? this.audience.id
              : this.getDefaultAudience.id,
            audienceNumId: Number(
              this.audience
                ? this.audience.num_id
                : this.getDefaultAudience.num_id,
            ),
            audienceType: this.audience
              ? this.audience.type
              : this.getDefaultAudience.type,
          },
        ],
      };
      await createSlide(payload)
        .then(async (resp) => {
          this.addedToLibraryData = {
            info: resp.data[0],
            type: 'slide',
          };
          this.isAddedToLibrary = true;
          this.prezOrSlideId = resp.data[0].id;
          this.downloadOperation.loading = false;
          this.downloadOperation.success = true;
          if (addFavorite) {
            await this.addToFavorite();
            this.setAddedToLibOrFavSnackbar({
              show: true,
              ctaText: 'snackBarItems.goToFavorites',
              snackbarText: 'snackBarItems.slideAddedToFav',
              navFn: this.goToFavLibrary,
              snackbarType: 'goToFav',
            });
          } else {
            this.postCompleteLog('addToPrezentationsLibraryStatus', true);
            this.setAddedToLibOrFavSnackbar({
              show: true,
              ctaText: 'snackBarItems.goToLibrary',
              snackbarText: 'snackBarItems.slideAddedToLib',
              navFn: this.goToLibrary,
              snackbarType: 'goToLib',
            });
          }
        })
        .catch((error) => {
          console.error('Error occurred while creating slide:', error);
          this.downloadOperation.loading = false;
          this.downloadOperation.success = false;
        });
    },
    formatProperty(str) {
      return str.charAt(0) === '/' ? str : `/${str}`;
    },
    setSmartTools(val) {
      this.isSmartToolsOpen = val;
      if (!val) {
        this.rightPanelComponent = null;
      }
      if (val) {
        const otherData = {
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
          [TD_TEMPLATE]: this.selectedTemplate.code,
        };
        trackSynthesisEvents.synthesisSmartToolsClick(
          this.currentUser,
          otherData,
        );
      }
    },
    openRightPanel(component, position = 'Smart tool') {
      if (this.rightPanelComponent === component) {
        this.rightPanelComponent = null;
      } else {
        EventBus.$emit('MINIMIZE_LEFTNAV');
        this.isSmartToolsOpen = true;
        this.rightPanelComponent = component;
      }
      this.placement = position;
      const otherData = {
        [TD_TEMPLATE]: this.selectedTemplate.code,
        [TD_SLIDE_NAME]: this.newFilesToUpload[0].name,
      };
      trackSynthesisEvents.synthesisModifyDatastyleClick(
        this.currentUser,
        otherData,
      );
    },
    handleVisibility() {
      if (this.processInProgress && document.visibilityState === 'hidden') {
        const endTime = performance.now();
        const timeSpent = Math.round((endTime - this.startTime) / 1000);
        const otherData = {
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_TIME_SPENT_IN_SECS]: timeSpent,
        };
        trackSynthesisEvents.synthesisUserTabSwitch(
          this.currentUser,
          otherData,
        );
      }
    },
  },
  mounted() {
    if (!this.isSynthesizeFullAccess) {
      this.$router.push('/home', () => {});
    }
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
      this.setSmartTools(false);
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
    document.addEventListener('visibilitychange', this.handleVisibility, false);
  },
  beforeDestroy() {
    this.synthesizeService.cancel();
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
    // this.resetAddedToLibrarySnackbarState();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isAddedToLibrary && this.execSummaryLoaded && !this.error) {
      this.pageChangeAction(next);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.synthesize-outer-container {
  display: flex;
  height: calc(100vh - 68px);
  flex-direction: column;

  // .generate-banner {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 14px 8px 14px 16px;
  //   gap: 4px;
  //   height: 48px;
  //   background: #e1f5fb;
  //   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  //   border-radius: 4px;

  //   .dismiss-btn {
  //     cursor: pointer;
  //   }
  // }

  .synthesize-container {
    display: flex;
    flex: 1;
    width: 100%;
    position: relative;
    min-height: 0;

    .main-content {
      padding: 20px;
      max-width: 1434px;
      width: 93%;
      margin: 0 auto;
      transition: width 0.5s ease-in;
      display: flex;
      flex-direction: column;

      &.right-panel-open {
        position: relative;
        width: calc(100% - 550px);
        transition: width 0.5s ease-in;
      }

      // .heading {
      //   font-family: 'Lato';
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 24px;
      //   line-height: 29px;
      //   color: #000000;

      //   .sub {
      //     font-family: 'Lato';
      //     font-style: normal;
      //     font-weight: 400;
      //     font-size: 12px;
      //     line-height: 14px;
      //     letter-spacing: 0.02em;
      //     color: #697077;
      //     margin-top: 4px;
      //   }
      // }

      .input-box {
        // margin-top: 8px;
        // margin-bottom: 16px;
        // padding: 10px 15px;
        // box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
        // border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        > div {
          gap: 10px;
          display: flex;
          align-items: center;

          .input-container {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
            width: calc(100% - 174px);

            .input-placeholder {
              color: #697077;
            }

            .input-placeholder,
            .input-file-name {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;

              &.disabled {
                cursor: default;
                pointer-events: none;
              }
            }
          }

          .resynthesize-container {
            display: flex;
            gap: 10px;
          }
        }

        .cancel-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 16px;
          gap: 8px;
          height: 32px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
          letter-spacing: normal;
          line-height: 19px;

          /* Color Styles (Old)/White */

          color: #21a7e0;

          background: #ffffff;
          border: 1px solid #21a7e0;
          border-radius: 25px;

          &.v-btn--disabled {
            border: none;
          }
        }

        .panel-items {
          display: flex;
          flex-wrap: wrap;
          column-gap: 14px;
          row-gap: 5px;
          flex: 1;

          > div {
            display: flex;
            gap: 4px;
            align-items: center;
            color: #21a7e0;
            cursor: pointer;
            padding: 8px 4px;

            &:hover {
              background-color: #41484d14;
              border-radius: 8px;
            }

            &.current {
              background: #e1f5fb;
              border-radius: 8px;
            }
          }
        }
      }

      .content-view {
        flex: 1;
        min-height: 0;
      }

      .slides-selection-container {
        height: 100%;
      }

      .slides-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        video {
          height: 100%;
          aspect-ratio: 16/9;
        }
      }

      .quick-access-toolbar {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    .smart-tool-switch {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 4;
      background: white;

      .divider {
        border: 0.75px solid #b6b6b6;
      }
    }

    .right-panel {
      box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3);
      width: 550px;
      padding-right: 55px;
      transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
        transform 0.5s ease-in;

      .template-menu {
        padding: 24px 16px;
        height: 100%;

        ::v-deep .tmpLists {
          flex: 1;
          min-height: 0;
        }
      }

      &.fade {
        opacity: 0.4;
        pointer-events: none;
      }

      &.hide {
        width: 0;
        transform: translateX(550px);
        padding-right: 0;
        opacity: 0;
        transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
          transform 0.5s ease-in;
      }
    }
  }
}

.synthesize-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  width: auto;
  height: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
  line-height: 19px;

  /* Color Styles (Old)/White */

  color: #ffffff;

  background: #21a7e0;
  border-radius: 25px;
}

.tutorial-menu {
  border-radius: 12px;
  width: 520px;
  background: white;
}
</style>
